import * as React from 'react'
import Layout from "../components/layout/layout";
import Box from "../components/box/box";
import {Link} from "gatsby";

const IndexPage = () => {
    return (
        <Layout pageTitle="Shared Web Hosting" pageKeywords="shared web hosting, professional hosting, stable hosting" pageDescription="Fast shared web hosting">
            <div className="row">
                <div className="col-md-3"/>
                <div className="col-md-6">
                    <div className="main">
                        <div className="title">
                            Shared Hosting
                            <div className="description">
                                Chat live <Link to="/livesupport">with our support</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="emptyblock"/>
            <div className="row">
                <div className="col-md-4">
                    <Box title1={"Starter"} title2={"Web Hosting"}
                         pricePerMonth={"20.00"} diskSpaceMB={15000} diskSpaceGB={15} bandwidthMB={100000}
                         bandwidthGB={100} link={"https://clients.belthosting.com/cart.php?a=add&pid=1"}/>
                </div>
                <div className="col-md-4">
                    <Box title1={"Business"} title2={"Web Hosting"}
                         pricePerMonth={"25.00"} diskSpaceMB={20000} diskSpaceGB={20} bandwidthMB={200000}
                         bandwidthGB={200} link={"https://clients.belthosting.com/cart.php?a=add&pid=3"}/>
                </div>
                <div className="col-md-4">
                    <Box title1={"Professional"} title2={"Web Hosting"}
                         pricePerMonth={"30.00"} diskSpaceMB={40000} diskSpaceGB={40} bandwidthMB={300000}
                         bandwidthGB={300} link={"https://clients.belthosting.com/cart.php?a=add&pid=4"}/>
                </div>
            </div>
        </Layout>
    )
}
export default IndexPage
