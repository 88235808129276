import * as React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons";

const Box = ({title1, title2, pricePerMonth, diskSpaceMB, diskSpaceGB, bandwidthMB, bandwidthGB, link}) => {
    return (
        <div className="box">
            <div className="title">
                <span className="title1">{title1}</span> <span className="title2">{title2}</span>
                <div className="startsfrom">Starts from ${pricePerMonth} per month!</div>
            </div>
            <div className="moreinfo">
                <div className="included">What is included?</div>
                <ul>
                    <li><strong>{diskSpaceMB} MB = {diskSpaceGB} GB disk space</strong></li>
                    <li><strong>{bandwidthMB} MB = {bandwidthGB} GB bandwidth</strong></li>
                    <li><strong>Supported PHP versions (7.1, 7.2, 7.3, 7.4, 8.0)</strong></li>
                    <li>ioncube installed</li>
                    <li>Unlimited Domains</li>
                    <li>Unlimited Subdomains</li>
                    <li>Unlimited MySql Databases</li>
                    <li>Unlimited POP3 Accounts</li>
                    <li>Unlimited FTP Accounts</li>
                    <li>Softaculous (Auto-Installer Included)</li>
                    <li>Free cPanel</li>
                    <li>Free Support</li>
                    <li>15-Day Money Back Guarantee!</li>
                    <li>Instant Setup</li>
                    <li>Latest Software & Hardware</li>
                    <li>24/7 Human Monitoring</li>
                    <li>Florida based location</li>
                </ul>
                <div className="text-center">
                    <a href={link}>
                        <div className="btn order_now"><FontAwesomeIcon
                            icon={faShoppingCart}/> Order now for ${pricePerMonth}!
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}
export default Box
